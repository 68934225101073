import { Icon } from '@nkc-frontend/cat-design';
import Tree, { TreeProps } from 'rc-tree';
import { FC } from 'react';

import classes from './DocumentDataTree.module.scss';

export type DocumentDataTreeProps = Omit<TreeProps, 'prefixCls'>;

export const DocumentDataTree: FC<DocumentDataTreeProps> = (props) => {
    return (
        <div className={'document-data-tree'}>
            <Tree
                {...props}
                prefixCls='document-tree'
                icon={(node) =>
                    !node.isLeaf ? (
                        <Icon
                            icon='arrow'
                            rotate={
                                !node.expanded
                                    ? node.data?.key === 'root'
                                        ? 180
                                        : -90
                                    : undefined
                            }
                            className={classes['tree-node-icon']}
                        />
                    ) : undefined
                }
            />
        </div>
    );
};
